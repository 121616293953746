import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import React from "react";
import verticalArrow from "../../assets/images/arrows-vertical.webp";
import * as Yup from 'yup';

type TFormFields = {
	email: string,
	phone: string,
	message: string;
};

type ContactSectionProps = {
	onFormSubmit(values: TFormFields): Promise<any>
}

export const ContactSection: React.FC<ContactSectionProps> = ({onFormSubmit}) => {
	const initialValues: TFormFields = {
		email: '',
		phone: '',
		message: ''
	};

	const formSchema = Yup.object().shape({
		email: Yup.string()
			.email('Sorry, but your email address is invalid!')
			.required('I need your email address to contact with you!'),
		phone: Yup.string()
			.matches(
				/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 
				'Phone number is not valid!'
			),
		message: Yup.string().min(10, 'Please write your message!')
	});

	const handleSubmit = (values: TFormFields, formik: FormikHelpers<TFormFields>) => {
		formik.setSubmitting(true);

		onFormSubmit(values)
		.then(() => {
			// Success
		})
		.catch(() => {
			// fail
		})
		.finally(() => {
			formik.setSubmitting(false);
		});
	}

	return <section className="-mt-12 pt-14 lg:pt-48 pb-52 sm:pb-[296px] bd:pb-[396px] lg:text-center container-full bg-contact">
		<header id="contact" className="flex justify-between sm:block uppercase font-extrabold text-3xl lg:text-2xl bd:text-3xl">
			<div>
				Get <br className="lg:hidden" />in touch
			</div>
			<div className="sm:-mt-14 sm:mx-auto lg:hidden self-end">
				<img src={verticalArrow} alt="" className="mx-auto" />
			</div>
		</header>
		<div className="lg:hidden font-bold text-accent text-xl">
			Message me
		</div>
		<div className="w-full bd:w-10/12 lg:h-[680px] lg:mt-16 mx-auto flex lg:border-accent lg:border-[1px] rounded-md overflow-hidden">
			<div className="w-full lg:w-1/2 bd:w-3/5 pt-12 bg-contact-form">
				<header className="hidden lg:block mb-10 font-extrabold text-3xl">
					Message me
				</header>
				<Formik
					onSubmit={handleSubmit}
					initialValues={initialValues}
					validationSchema={formSchema}
				>
					{({ isSubmitting }) => (
					<Form className="w-full mx-auto space-y-6 sm:space-y-10 lg:w-2/3 lg:space-y-8 bd:space-y-10">
						<Field type="email" name="email" placeholder="your e-mail" className="w-full h-14 px-5 block bg-input text-input font-bold sm:w-[calc(50%-16px)] sm:hidden lg:w-full lg:block" />
						<Field type="text" name="phone" placeholder="your phone number" className="w-full h-14 px-5 block bg-input text-input font-bold sm:w-[calc(50%-16px)] sm:ml-8 sm:hidden lg:w-full lg:ml-0 lg:block" />
						<div className="relative -top-10 hidden sm:block lg:hidden">
							<Field type="email" name="email" placeholder="your e-mail" className="w-full h-14 px-5 bg-input text-input font-bold sm:w-[calc(50%-16px)] lg:w-full" />
							<Field type="text" name="phone" placeholder="your phone number" className="w-full h-14 px-5 bg-input text-input font-bold sm:w-[calc(50%-16px)] sm:ml-8 lg:w-full lg:ml-0" />
							<div className="-mb-10"></div>
						</div>
						<Field as="textarea" name="message" placeholder="message" className="w-full min-h-[220px] max-h-0 px-5 py-3 bg-input text-input font-bold" />
						<button type="submit" disabled={isSubmitting} className="w-full px-16 py-4 bg-accent rounded-md font-bold text-input bd:w-auto">
							Send message
						</button>
					</Form>
					)}
				</Formik>
			</div>
			<div className="w-1/2 bd:w-2/5 py-14 hidden lg:flex flex-col justify-between text-slate-200 bg-contact-form-side">
				<header className="font-extrabold text-2xl bd:text-3xl">
					Want to make<br />
					something BIG<br />
					with me?
				</header>
				<div className="font-bold">
					Fill in the contact form.<br/>
					I will surely answer ASAP.
				</div>
			</div>
		</div>
	</section>;
}
