import classNames from "classnames";
import React, { useCallback, useState } from "react";
import "./SkillsSection.scss";
import arrowLeft from "../../assets/images/arrow-left.webp";
import arrowRight from "../../assets/images/arrow-right.webp";
import arrowLeftDark from "../../assets/images/arrow-left-dark.png";
import arrowRightDark from "../../assets/images/arrow-right-dark.png";
import { useDarkMode } from "usehooks-ts";

type SkillsSectionProps = {
	skills: TSkill[]
};

export const SkillsSection: React.FC<SkillsSectionProps> = ({skills}) => {
	const {isDarkMode} = useDarkMode();
	const [activeSkill, setActiveSkill] = useState<TSkill>(skills[0]);

	const setPrevious = (event) => {
		const index = skills.indexOf(activeSkill);

		if(index == 0)
			setActiveSkill(() => skills[skills.length - 1]);
		else
			setActiveSkill(() => skills[index - 1]);

		event.preventDefault();
	};

	const setNext = (event) => {
		const index = skills.indexOf(activeSkill);

		if(index == skills.length - 1)
			setActiveSkill(() => skills[0]);
		else
			setActiveSkill(() => skills[index + 1]);

		event.preventDefault();
	};

	return <section id="skills" className="mt-40 container-full">
		<header className="mb-10 desktop:mb-40 text-3xl text-center font-extrabold leading-none">
			MY SKILLS<br />
			<span className="text-2xl text-accent">
				It's just a part!
			</span>
		</header>

		<div className="flex flex-col lg:flex-row">
			<div className="w-full lg:w-7/12 bd:w-8/12 skills-list">
				{skills.map(skill => <div
					key={skill.name}
					className={classNames(skill === activeSkill ? 'active' : false)}
					onClick={() => setActiveSkill(skill)}
				>
					<div className="point" />
					<div className="text">{skill.name}</div>
				</div>)}
			</div>
			<div className="w-full lg:w-5/12 bd:w-4/12 -mt-10 lg:mt-48 z-10">
				<div className="w-full flex justify-between">
					<a href="#" className="self-center" onClick={(e) => setPrevious(e)}>
						<img src={isDarkMode ? arrowLeftDark : arrowLeft} alt="" />
					</a>
					<div className="w-3/4">
						<header className="mb-5 font-extrabold text-2xl bd:text-3xl">{activeSkill.name}</header>
						<p className="lg:min-h-[196px] text-justify bd:leading-7">
							{activeSkill.content}
						</p>
					</div>
					<a href="#" className="self-center" onClick={(e) => setNext(e)}>
						<img src={isDarkMode ? arrowRightDark : arrowRight} alt="" />
					</a>
				</div>
			</div>
		</div>
	</section>;
}

export type TSkill = {
	name: string,
	content: string
}
