import React from "react";
import mainImage from "../../assets/images/main-picture.webp";
import verticalArrow from "../../assets/images/arrows-vertical.webp";
import './HelloSection.scss';

type HelloSectionProps = {
	roles: string[]
};

export const HelloSection: React.FC<HelloSectionProps> = ({roles}) => {
	return <section id="top" className="-mt-[105px] aspect-[1918/1319]">
		<div className="relative h-[100vh] flex justify-center text-center container-full">
			<div className="self-center">
				<img src={mainImage} alt="main-image" className="mx-auto mb-9 w-[229px] h-[229px] object-cover rounded-full" />
				<div className="text-accent font-extrabold text-[28px] bd:text-4xl leading-none tracking-wider">
					Krzysztof Szostak
				</div>
				<div className="mt-1">
					<ul className="font-bold list-items-split">
						{roles.map(i =>
							<li key={i} className="inline-block">
								{i}
							</li>
						)}
					</ul>
				</div>
				<div className="mt-10" />
			</div>

			<div className="absolute bottom-8 text-center lg:text-left font-bold container-full">
				<img src={verticalArrow} alt="" className="lg:mr-6 inline-block" />
				<span className="hidden lg:inline">
					{' '}Start scrolling
				</span>
			</div>
		</div>
	</section>;
}
