import * as React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"
import { HelloSection } from "../components/sections/HelloSection";
import { AboutMeSection } from "../components/sections/AboutMeSection";
import { BlogPostSection } from "../components/sections/BlogPostsSection";
import { SkillsSection } from "../components/sections/SkillsSection";
import { KeepScrollingSection } from "../components/sections/KeepScrollingSection";
import { PortfolioSection } from "../components/sections/PortfolioSection";
import { ContactSection } from "../components/sections/ContactSection";

import blogImage1 from "../assets/images/blog/blog-1.webp";
import blogImage2 from "../assets/images/blog/blog-2.webp";
import blogImage3 from "../assets/images/blog/blog-3.webp";
import project1 from "../assets/images/projects/project-1.webp";
import project2 from "../assets/images/projects/project-2.webp";
import project3 from "../assets/images/projects/project-3.webp";
import project4 from "../assets/images/projects/project-4.webp";
import project5 from "../assets/images/projects/project-5.webp";
import project6 from "../assets/images/projects/project-6.webp";


const PageIndex: React.FC = () => {
	const roles = ['Backend', 'DevOps', 'Code Fanatic'];
	const info = [
		{
			name: 'Name',
			value: 'Krzysztof Szostak'
		}, {
			name: 'Age',
			value: '24'
		}, {
			name: 'Experience',
			value: '5+'
		},
	];

	const posts = [
		{
			title: 'LOREM IPSUM - GREAT OPTION?',
			content: 'Cras a vehicula velit. Nam magna nibh, varius ac augue non, ultrices volutpat ante. Suspendisse tincidunt ipsum conte volde...',
			date: '03.11.2021',
			url: '#',
			image: blogImage1
		}, {
			title: 'Ur mom - why is she so lovely...',
			content: 'Vepsum turpis, vel scelerisque tortor facilisis in. Aenean sed dapibus diam. Class aptent taciti sociosqu ad litora torquent per de...',
			date: '03.11.2021',
			url: '#',
			image: blogImage2
		}, {
			title: 'Workout vs. Workin vs. Workoff',
			content: 'Suspendisse tincidunt ipsum sed odio tempor, ac porttitor quam tristique. Sed varius ipsum turpis, vel scelerisque tortor...',
			date: '03.11.2021',
			url: '#',
			image: blogImage3
		}
	];

	const socials = [
		{
			name: 'Facebook',
			url: '#'
		}, {
			name: 'Dribble',
			url: '#'
		}, {
			name: 'LinkedIn',
			url: '#'
		}
	];

	const cvUrl = "#";

	const skills = [
		{
			name: 'NodeJS',
			content: 'Proin scelerisque dui nibh. Nullam nibh odio, cursus a urna nec, luctus pulvinar orci. Mauris elementum suscipit nunc. Curabitur ornare lobortis leo eu ultrices. Donec ut condimentum mi. Sed volutpat quam quis eros dapibus convallis. Aenean consectetur pretium eros ut elementum.'
		}, {
			name: 'Docker',
			content: 'Do me like a Morawiecki does you.'
		}, {
			name: 'AWS',
			content: 'Proin scelerisque dui nibh. Nullam nibh odio, cursus a urna nec, luctus pulvinar orci. Mauris elementum suscipit nunc. Curabitur ornare lobortis leo eu ultrices. Donec ut condimentum mi. Sed volutpat quam quis eros dapibus convallis. Aenean consectetur pretium eros ut elementum.'
		}, {
			name: 'Kubernetes',
			content: 'Do me like a Morawiecki does you.'
		}, {
			name: 'Typescript',
			content: 'Proin scelerisque dui nibh. Nullam nibh odio, cursus a urna nec, luctus pulvinar orci. Mauris elementum suscipit nunc. Curabitur ornare lobortis leo eu ultrices. Donec ut condimentum mi. Sed volutpat quam quis eros dapibus convallis. Aenean consectetur pretium eros ut elementum.'
		}, {
			name: 'Terraform',
			content: 'Do me like a Morawiecki does you.'
		}, {
			name: 'Serverless',
			content: 'Proin scelerisque dui nibh. Nullam nibh odio, cursus a urna nec, luctus pulvinar orci. Mauris elementum suscipit nunc. Curabitur ornare lobortis leo eu ultrices. Donec ut condimentum mi. Sed volutpat quam quis eros dapibus convallis. Aenean consectetur pretium eros ut elementum.'
		}, {
			name: 'Ansible',
			content: 'Do me like a Morawiecki does you.'
		},
	];

	const projects = [
		{
			title: 'The Genius Cooking',
			technologies: ['AWS, Typescript, Ansible'],
			image: project1,
			url: "#",
		}, {
			title: 'The Genius Cooking',
			technologies: ['AWS, Typescript, Ansible'],
			image: project2,
			url: "#",
		}, {
			title: 'The Genius Cooking',
			technologies: ['AWS, Typescript, Ansible'],
			image: project3,
			url: "#",
		}, {
			title: 'The Genius Cooking',
			technologies: ['AWS, Typescript, Ansible'],
			image: project4,
			url: "#",
		}, {
			title: 'The Genius Cooking',
			technologies: ['AWS, Typescript, Ansible'],
			image: project5,
			url: "#",
		}, {
			title: 'The Genius Cooking',
			technologies: ['AWS, Typescript, Ansible'],
			image: project6,
			url: "#",
		},
	];

	const onContactFormSubmit = async ({email, phone, message}) => {
		// if there is any error, throw it.
		console.log("Send contact form!");
	}

	return <Layout>
		<Seo />

		<HelloSection roles={roles} />
		<AboutMeSection info={info} socials={socials} cvUrl={cvUrl} />
		<BlogPostSection posts={posts} />
		<SkillsSection skills={skills} />
		<KeepScrollingSection />
		<PortfolioSection projects={projects} />
		<ContactSection onFormSubmit={onContactFormSubmit} />
	</Layout>;
}

export default PageIndex
