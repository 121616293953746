import React from "react";
import arrowsVertical from "../../assets/images/arrows-vertical.webp";

export const KeepScrollingSection: React.FC = () => {
	return <section className="mt-[175px] bd:mt-[300px] text-center font-bold container-full">
		<img src={arrowsVertical} alt="" className="mx-auto mb-8" />
		Keep scrolling<br/>
		for Portfolio and Contact
	</section>
}
