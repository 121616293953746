import React from "react";
import aboutMe from "../../assets/images/about-me-bg.webp";
import { LinkButton } from "../LinkButton";


type AboutMeSectionProps = {
	info: TInfo[],
	socials: TSocial[],
	cvUrl: string
};

export const AboutMeSection: React.FC<AboutMeSectionProps> = ({info, socials, cvUrl}) => {
	return <section id="aboutMe">
		<div className="container-full">
			<div className="sm:w-4/12 block lg:hidden text-accent text-3xl font-extrabold uppercase">
				Me <br className="sm:hidden"/>&amp; My Blog
			</div>
		</div>
		<img src={aboutMe} alt="about-me" className="w-full mt-10 sm:hidden" />
		<div className="container-full flex gap-8 lg:gap-0">
			<aside className="hidden lg:block w-1/12 text-accent text-3xl font-extrabold uppercase">
				<span className="w-[248px] mt-[248px] inline-block -rotate-90 origin-top-left">
					Me &amp; My Blog
				</span>
			</aside>
			<div className="w-1/2 lg:w-3/12 mt-[45px] lg:mt-[75px] hidden sm:block">
				<img src={aboutMe} alt="about-me" className="lg:w-3/4" />

				<div className="mt-10 lg:hidden space-y-10">
					{socials.map(social => <LinkButton key={social.name} to={social.url} target="_blank" className="w-full py-4">{social.name}</LinkButton>)}
				</div>
			</div>
			<div className="w-full sm:w-1/2 lg:w-8/12 mt-[45px] lg:mt-[75px]">
				<header className="mb-2 font-extrabold text-3xl uppercase">
					<span className="text-accent">&rdquo;</span>
					Hello&nbsp;
					<span className="text-accent">World</span>
					&rdquo;
				</header>
				<p className="text-justify leading-5 bd:leading-7">
					Cras a vehicula velit. Nam magna nibh, varius ac augue non, ultrices volutpat ante. Suspendisse tincidunt ipsum sed odio tempor, ac porttitor quam tristique. Sed varius ipsum turpis, vel scelerisque tortor facilisis in. Aenean sed dapibus diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent dolor nisl, pellentesque a leo consectetur, lacinia ultricies naras a vehicula velit. Nam magna nibh, varius ac augue non, ultrices volutpat ante. Suspendisse tincidunt ipsum sed odio tempor, ac porttitor quam tristique. Sed varius ipsum turpis, vel scelerisque tortor facilisis in. Aenean sed dapibus diam. Class aptent taciti sociosqu ad litora torquent.
				</p>
				<ul className="mt-8 space-y-4 lg:space-x-14 lg:space-y-0">
					{info.map(i => <li key={i.name} className="lg:inline-block">
							<b>{i.name}:</b>&nbsp;
							{i.value}
						</li>
					)}
				</ul>

				<LinkButton className="w-full mt-10 hidden sm:block lg:hidden py-4" to={cvUrl} target="_blank" active>
					Download CV
				</LinkButton>

				<div className="mt-16 block sm:hidden lg:block">
					<header className="mb-4">
						<b>All my links:</b>
					</header>
					<div className="flex flex-col sm:flex-row gap-y-5 sm:gap-0 bd:inline-block justify-between bd:space-x-5">
						{socials.map(social => <LinkButton key={social.name} to={social.url} target="_blank" className="py-[14px] sm:py-[10px]">{social.name}</LinkButton>)}

						<LinkButton className="mt-8 sm:mt-0 py-[14px] sm:py-[10px] block bd:hidden" to={cvUrl} target="_blank" active>
							Download CV
						</LinkButton>
					</div>

					<LinkButton className="hidden bd:inline-block float-right w-1/4" to={cvUrl} target="_blank" active>
						Download CV
					</LinkButton>
				</div>
			</div>
		</div>
	</section>;
}

export type TInfo = {
	name: string,
	value: string
};

export type TSocial = {
	name: string,
	url: string
}
