import { Link } from "gatsby";
import React from "react";
import sideArrow from "../../assets/images/arrows-side.png";
import verticalArrow from "../../assets/images/arrows-vertical.webp";
import { LinkButton } from "../LinkButton";

type BlogPostSectionProps = {
	posts: TPost[]
};

export const BlogPostSection: React.FC<BlogPostSectionProps> = ({posts}) => {
	return <section id="blog" className="container-full mt-40 bd:mt-60 bg-blog">
		<div className="mb-10 flex justify-between sm:block lg:hidden">
			<img src={verticalArrow} alt="" className="hidden sm:block mx-auto mb-4" />
			<div>
				<div className="mb-1 text-accent font-bold text-2xl">Recent posts</div>
				<header className="font-extrabold text-3xl uppercase">
					Check<br />
					my blog
				</header>
			</div>
			<div className="block sm:hidden self-center">
				<img src={verticalArrow} alt="" />
			</div>
		</div>
		<div className="pt-10 flex">
			<aside className="w-3/12 bd:w-2/12 mt-14 hidden lg:block">
				<div className="mb-1 text-accent font-bold text-2xl">Recent posts</div>
				<header className="font-extrabold text-3xl uppercase">
					Check<br />
					my blog
				</header>
				<img src={sideArrow} alt="" className="mt-20" />
			</aside>
			<div className="w-full lg:w-9/12 bd:w-10/12 flex flex-col lg:flex-row gap-20 sm:gap-24 lg:gap-8 bd:gap-20">
				{posts.map(post => <div key={post.title} className="flex flex-col sm:flex-row lg:block gap-8">
					<img src={post.image} alt="post-image" className="sm:w-1/2 lg:w-full" />
					<div className="sm:w-1/2 lg:w-full lg:mt-5 lg:mb-2">
						<header className="font-bold text-base lg:text-xl bd:text-2xl">{post.title}</header>
						<p className="text-justify">
							{post.content}
						</p>
						<div className="mt-5 flex justify-between gap-1">
							<b className="self-center">{post.date}</b>
							<Link to={post.url} className="block lg:hidden text-accent font-bold">Read more</Link>
							<LinkButton to={post.url} className="hidden lg:block w-2/3 px-1">Read more</LinkButton>
						</div>
					</div>
				</div>)}
			</div>
		</div>
	</section>;
}

export type TPost = {
	title: string,
	content: string,
	date: string,
	url: string,
	image: string
};
