import classNames from "classnames";
import { Link } from "gatsby";
import React, { useState } from "react";

type PortfolioProps = {
	projects: TProject[]
};

export const PortfolioSection: React.FC<PortfolioProps> = ({projects}) => {
	const [itemHover, setItemHover] = useState(-1);

	const getMargin = (index) => {
		if(index === 0) return '';
		if(index === 1) return 'md:mt-5';
		if(index === 2) return 'lg:mt-10';

		if(index % 3 === 0) return 'lg:-mt-10';
		if(index % 3 === 1) return 'lg:-mt-5';
		if(index % 3 === 2) return '';
	}

	return <section id="portfolio" className={classNames(
			"max-w-[600px] md:max-w-none mt-6 mx-auto pt-20 lg:pt-[176px] pb-60 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-5 space-y-8 md:space-y-0 container-full hover-opacity-highlight bg-portfolio",
			itemHover === -1 ? '' : 'has-hover'
		)}
	>
		{projects.map((p, i) => <div key={i}
			className={classNames(
				"relative aspect-[587/398] rounded-md overflow-hidden cursor-pointer group",
				getMargin(i),
			)}
			onMouseOver={() => setItemHover(i)}
			onMouseOut={() => setItemHover(-1)}
		>
			<Link to={p.url} target="_blank">
				<img src={p.image} alt="" className="w-full" />
				<header className="absolute bottom-0 md:-bottom-14 group-hover:bottom-0 left-0 w-full sm:h-14 py-2 sm:py-0 px-5 flex flex-col sm:flex-row justify-between bg-accent text-white transition-all">
					<span className="font-extrabold sm:self-center">{p.title}</span>
					<span className="sm:self-center">{p.technologies.join(', ')}</span>
				</header>
			</Link>
		</div>)}
	</section>
}

type TProject = {
	title: string,
	technologies: string[],
	image: string,
	url: string
};
